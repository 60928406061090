import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import DropDown from "../../components/DropDown";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import {
  addProduct,
  getAllCompany,
  getCompanyLocation,
  getProductDetails,
  updateProduct,
} from "../../services/services";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";
import { numOnly } from "../../helper/helper";

const Addproduct = () => {
  const [searchParam] = useSearchParams();
  const id = searchParam.get("id");
  const isSlider = useSlider();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      company: "",
      itemName: "",
      itemCode: "",
      labelPerRoll: "",
      rollPack: "",
      price: "",
      locations: [],
      allLocations: false,
    },
    validationSchema: yup.object().shape({
      company: yup.object().shape({
        value: yup.string().required().label(t("product.company")),
      }),
      itemName: yup.string().required().label(t("product.itemName")).trim(),
      itemCode: yup.string().required().label(t("prepList.items")).trim(),

      labelPerRoll: yup
        .number()
        .required()
        .min(1)
        .positive()
        .typeError(t("errorMsg.invalidNumber"))
        .label(t("product.labelPerRoll")),
      rollPack: yup
        .number()
        .required()
        .min(1)
        .positive()
        .required()
        .label(t("product.rollPack"))
        .typeError(t("errorMsg.invalidNumber")),
      price: yup
        .number()
        .required()
        .min(1)
        .positive()
        .required()
        .label(t("product.price"))
        .typeError(t("errorMsg.invalidNumber")),

      locations: yup.array().when("allLocations", {
        is: (value) => !value,
        then: () => yup.array().min(1).label(t("prepList.prepLocation")),
      }),
    }),
    onSubmit: (values) => {
      let body = {
        name: values?.itemName,
        itemCode: values?.itemCode?.toUpperCase(),
        labelPerRoll: values?.labelPerRoll,
        rollsPerPack: values?.rollPack,
        price: values?.price,
        assignAll: values?.allLocations,
      };

      if (!values?.allLocations)
        body.locations = values?.locations?.map((i) => i.value);
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) =>
      !!id
        ? updateProduct(id, body, values?.company?.domain)
        : addProduct(body, values?.company?.domain),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate("../product", {
        state: location?.state ? location?.state : values?.company,
      });
    },
  });

  const loadLocations = async (search, loadedOptions, { page }) => {
    let resp =
      values?.company?.domain &&
      (await getCompanyLocation(
        values?.company?.domain,
        page,
        constant.PER_PAGE_TEN,
        search
      ));

    let array = (await resp?.data?.data?.locations?.locations) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
      })),
      hasMore:
        loadedOptions.length == resp?.data?.data?.locations?.total
          ? false
          : true,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadCompany = async (search, loadedOptions, { page }) => {
    let resp = await getAllCompany(page, constant.PER_PAGE_TEN, search);
    let array = (await resp?.data?.data?.companies) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
        domain: item?.uuid,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  useQuery({
    queryKey: ["product-details", id],
    queryFn: async () => {
      let resp =
        location?.state?.domain &&
        id &&
        (await getProductDetails(id, location?.state?.domain, true));
      if (resp?.data?.data) {
        setValues({
          ...values,
          company: location?.state,
          itemName: resp?.data?.data?.name,
          itemCode: resp?.data?.data?.itemCode,
          labelPerRoll: resp?.data?.data?.labelPerRoll,
          rollPack: resp?.data?.data?.rollsPerPack,
          price: resp?.data?.data?.price,
          locations: resp?.data?.data?.locations?.map((i) => ({
            label: i.name,
            value: i._id,
          })),
          allLocations: resp?.data?.data?.assignAll,
        });
      }
      return true;
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12}>
            <NavigateBack path={"../product"} state={location?.state}>
              {!!id ? t("product.editProduct") : t("product.addNewProduct")}
            </NavigateBack>
          </Col>
        </Row>
        <div className="basic_Info">
          <Row>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="company" className="companyLabel">
                  {t("product.company")} <span className="text-danger">*</span>
                </label>
                <DropDown
                  placeholder={t("user.selectCompany")}
                  id="company"
                  loadOptions={loadCompany}
                  isDisabled={!!id}
                  isClearable={false}
                  onChange={(e) => {
                    setFieldValue("company", e);
                    setFieldValue("locations", []);
                  }}
                  value={values?.company}
                  onBlur={() => setFieldTouched("company", true)}
                />
                <small className="text-danger ms-1">
                  {touched.company && errors.company?.value}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="itemName" className="companyLabel">
                  {t("product.itemName")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="itemName"
                  name="itemName"
                  value={values?.itemName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("product.enterItemName")}
                />
                <small className="text-danger ms-1">
                  {touched.itemName && errors.itemName}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="itemCode" className="companyLabel">
                  {t("product.itemCode")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="itemCode"
                  name="itemCode"
                  value={values?.itemCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ textTransform: "uppercase" }}
                  className="commonInput"
                  placeholder={t("product.enterItemCode")}
                />
                <small className="text-danger ms-1">
                  {touched.itemCode && errors.itemCode}
                </small>
              </div>
            </Col>

            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="labelPerRoll" className="companyLabel">
                  {t("product.labelPerRoll")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="labelPerRoll"
                  name="labelPerRoll"
                  value={values?.labelPerRoll}
                  onChange={handleChange}
                  onKeyDown={(e) => numOnly(e)}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("product.enterLabelPerRoll")}
                />
                <small className="text-danger ms-1">
                  {touched.labelPerRoll && errors.labelPerRoll}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-3">
                <label htmlFor="rollPack" className="companyLabel">
                  {t("product.rollPack")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="rollPack"
                  name="rollPack"
                  value={values?.rollPack}
                  onChange={handleChange}
                  onKeyDown={(e) => numOnly(e)}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("product.enterRollPack")}
                />
                <small className="text-danger ms-1">
                  {touched.rollPack && errors.rollPack}
                </small>
              </div>
            </Col>

            <Col md={6}>
              <div className="position-relative mt-3">
                <label htmlFor="price" className="companyLabel">
                  {t("product.price")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="price"
                  name="price"
                  value={values?.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("product.enterPrice")}
                />
                <small className="text-danger ms-1">
                  {touched.price && errors.price}
                </small>
              </div>
            </Col>

            <Col md={6}>
              <div className="mt-3">
                <label htmlFor="location" className="companyLabel">
                  {t("product.location")} <span className="text-danger">*</span>
                </label>

                <DropDown
                  placeholder={t("product.enterLocation")}
                  id="locations"
                  loadOptions={loadLocations}
                  isClearable={false}
                  isMulti={true}
                  depends={values?.company?.domain}
                  value={values?.locations}
                  onChange={(e) => setFieldValue("locations", e)}
                  onBlur={() => setFieldTouched("locations", true)}
                />
                <small className="text-danger ms-1">
                  {touched?.locations && errors?.locations}
                </small>
              </div>
            </Col>
            <div className="customer_Access mt-0">
              <div className="access_Child">
                <div className="form-group">
                  <input
                    type="checkbox"
                    id="allLocations"
                    name="allLocations"
                    value={values?.allLocations}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values?.allLocations === true}
                  />
                  <label htmlFor="allLocations">
                    <span className="fontSize14 ms-2">
                      {t("product.allLocations")}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <Row>
              <Col className="mt-4" xl={12}>
                <button
                  className="userBtn btn-theme m-0"
                  type="button"
                  onClick={handleSubmit}
                >
                  {!!id ? t("globals.saveChanges") : t("globals.add")}
                </button>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Addproduct;
