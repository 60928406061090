import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DeleteModal from "../../components/DeleteModal";
import useSlider from "../../hooks/useSlider";
import { deletePlan, getPlans } from "../../services/services";
import { CheckMark } from "../../SvgIcons/allIcons";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";
import AddPlanModel from "./AddPlanModel";
import "./paymentAndBilling.css";
import { convertToTitleCase } from "../../utils/common";

const PaymentAndBilling = () => {
  const { t } = useTranslation();
  const isSlider = useSlider();
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["plan-list"],
    queryFn: async () => {
      const resp = await getPlans();
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deletePlan(id),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <section
        className={isSlider ? "body-content close" : "body-content open"}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2 className="main-heading fontSize24">
              {t("payment.paymentBilling")}
            </h2>
            <button
              className="userBtn btn-theme fontSize18"
              onClick={() => setShow(true)}
            >
              {t("payment.createNew")}
            </button>
          </div>

          <Row>
            <Col md={12}>
              {data?.plans && data?.plans?.length > 0 ? (
                data?.plans?.map((item, index) => {
                  return (
                    <div
                      className="plan-parent d-flex justify-content-between align-items-center"
                      key={index}
                    >
                      <div>
                        <h3 className="plan_Txt fontSize20">{item?.name}</h3>
                        <h3 className="freeTxt fontSize24">
                          ${item?.unitAmount}
                          <span className="fontSize16">/{item?.interval}</span>
                        </h3>
                        {item?.companyId && (
                          <p className="fontSize16">{`For ${item?.companyDetails?.name}`}</p>
                        )}

                        <p className="fontSize16">
                          Last Updated: {moment(item?.updatedAt).format("ll")}
                        </p>
                      </div>
                      <div className="includes_Parent">
                        <h3 className="includerTxt fontSize14">INCLUDES:</h3>
                        {item?.addOns?.map((i, ind) => {
                          return (
                            <p className="includes_Data" key={ind}>
                              <CheckMark />{" "}
                              <span className="fontSize14">
                                {convertToTitleCase(i)}
                              </span>
                            </p>
                          );
                        })}
                      </div>
                      <div>
                        <button
                          className="userBtn btn-theme fontSize18 me-2"
                          onClick={() => setShow(item)}
                        >
                          {t("payment.edit")}
                        </button>
                        <button
                          className="delBtn btn-theme fontSize18"
                          onClick={() => setShowDelete(item?._id)}
                          type="button"
                        >
                          {t("globals.delete")}
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <center className="mt-4">
                  <h4 className="main-heading fontSize24">
                    {t("payment.noPlansFound")}
                  </h4>
                </center>
              )}
            </Col>
          </Row>
        </div>
      </section>

      {/* Start Create New Plan Modal */}
      {!!show && (
        <AddPlanModel show={show} setShow={setShow} refetch={refetch} />
      )}

      {/******************************Delete Modal*************************/}
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default PaymentAndBilling;
