import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import DropDown from "../../components/DropDown";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import {
  updatePrinter,
  getAllCompany,
  getCompanyLocation,
  getPrinterDetails,
  addPrinter,
} from "../../services/services";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";
import { numOnly } from "../../helper/helper";

const ModifyPrinter = () => {
  const [searchParam] = useSearchParams();
  const id = searchParam.get("id");
  const isSlider = useSlider();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = {
    deviceId: "",
    name: "",
    model: "",
    serialNumber: "",
    trackingNumber: "",
    locationId: "",
  }

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      company: null,
      ...initialValues
    },
    validationSchema: yup.object().shape({
      deviceId: yup
        .string()
        .required()
        .label(t("printer.deviceId"))
        .trim()
        .min(1),
      name: yup
        .string()
        .required()
        .label(t("printer.name"))
        .trim()
        .min(1),
      serialNumber: yup
        .string()
        .required()
        .label(t("printer.serialNumber"))
        .trim()
        .min(1),
      model: yup
        .string()
        .required()
        .label(t("printer.printerModel"))
        .trim()
        .min(1),
      trackingNumber: yup
        .string()
        .required()
        .label(t("printer.trackingNumber"))
        .trim()
        .min(1),
      locationId: yup.object().shape({
        value: yup.string()
          .required()
          .label(t("printer.location"))
      })

    }),
    onSubmit: (values) => {
      const { company, locationId, ...rest } = values
      rest.locationId = locationId?.value
      mutation.mutate(rest);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) =>
      !!id
        ? updatePrinter(values?.company?.domain, id, body,)
        : addPrinter(body, values?.company?.domain),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate("../printers", {
        state: location?.state ? location?.state : values?.company,
      });
    },
  });

  const loadLocations = async (search, loadedOptions, { page }) => {
    let resp =
      values?.company?.domain &&
      (await getCompanyLocation(
        values?.company?.domain,
        page,
        constant.PER_PAGE_TEN,
        search
      ));

    let array = (await resp?.data?.data?.locations?.locations) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
      })),
      hasMore:
        loadedOptions.length == resp?.data?.data?.locations?.total
          ? false
          : true,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadCompany = async (search, loadedOptions, { page }) => {
    
    let resp = await getAllCompany(page, constant.PER_PAGE_TEN, search, constant.ORDER_BY.ASC, "name", constant.COMPANY_HAVING_LOCATIONS.TRUE);
    let array = (await resp?.data?.data?.companies) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
        domain: item?.uuid,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  useQuery({
    queryKey: ["printer-details", id],
    queryFn: async () => {
      let resp =
        location?.state?.domain &&
        id &&
        (await getPrinterDetails(location?.state?.domain, id, true));
      if (resp?.data?.data) {
        const formValues = {}
        Object.keys(initialValues).forEach((key) => formValues[key] = resp?.data?.data[key])
        formValues.locationId = { value: resp?.data?.data.locationDetails._id, label: resp?.data?.data.locationDetails.name }
        setValues({
          ...values,
          company: location?.state,
          ...formValues
        });
      }
      return true;
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12}>
            <NavigateBack path={"../printers"} state={location?.state}>
              {t("printer.assignPrinter")}
            </NavigateBack>
          </Col>
        </Row>
        <div className="basic_Info">
          <Row>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="company" className="companyLabel">
                  {t("printer.company")} <span className="text-danger">*</span>
                </label>
                <DropDown
                  placeholder={t("user.selectCompany")}
                  id="company"
                  loadOptions={loadCompany}
                  isDisabled={!!id}
                  isClearable={false}
                  onChange={(e) => {
                    setFieldValue("company", e);
                    setFieldValue("locationId", "");
                  }}
                  value={values?.company}
                  onBlur={() => setFieldTouched("company", true)}
                />
                <small className="text-danger ms-1">
                  {touched.company && errors.company?.value}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="deviceId" className="companyLabel">
                  {t("printer.deviceId")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="deviceId"
                  name="deviceId"
                  value={values?.deviceId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("printer.enterDeviceId")}
                />
                <small className="text-danger ms-1">
                  {touched.deviceId && errors.deviceId}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="name" className="companyLabel">
                  {t("printer.name")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={values?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("printer.enterPrinterName")}
                />
                <small className="text-danger ms-1">
                  {touched.name && errors.name}
                </small>
              </div>
            </Col>

            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="printerModel" className="companyLabel">
                  {t("printer.printerModel")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="model"
                  name="model"
                  value={values?.model}
                  onChange={handleChange}
                  onKeyDown={(e) => numOnly(e)}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("printer.enterModel")}
                />
                <small className="text-danger ms-1">
                  {touched.model && errors.model}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-3">
                <label htmlFor="serialNumber" className="companyLabel">
                  {t("printer.serialNumber")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="serialNumber"
                  name="serialNumber"
                  value={values?.serialNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("printer.enterSerialNumber")}
                />
                
                <small className="text-danger ms-1">
                  {touched.serialNumber && errors.serialNumber}
                </small>
              </div>
            </Col>

            <Col md={6}>
              <div className="position-relative mt-3">
                <label htmlFor="trackingNumber" className="companyLabel">
                  {t("printer.trackingNumber")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="trackingNumber"
                  name="trackingNumber"
                  value={values?.trackingNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("printer.enterTrackingNumber")}
                />
                <small className="text-danger ms-1">
                  {touched.trackingNumber && errors.trackingNumber}
                </small>
              </div>
            </Col>

            {values.company && values.company !== null &&
              <Col md={6}>
                <div className="mt-3">
                  <label htmlFor="locationId" className="companyLabel">
                    {t("printer.locationName")} <span className="text-danger">*</span>
                  </label>

                  <DropDown
                    placeholder={t("printer.selectLocation")}
                    id="locationId"
                    loadOptions={loadLocations}
                    isClearable={false}
                    isMulti={false}
                    depends={values?.company?.domain}
                    value={values?.locationId}
                    onChange={(e) => setFieldValue("locationId", e)}
                    onBlur={() => setFieldTouched("locationId", true)}
                  />
                  <small className="text-danger ms-1">
                    {touched?.locationId && errors?.locationId?.value}
                  </small>
                </div>
              </Col>}
            <Row>
              <Col className="mt-4" xl={12}>
                <button
                  className="userBtn btn-theme m-0"
                  type="button"
                  onClick={handleSubmit}
                >
                  {!!id ? t("globals.saveChanges") : t("printer.assign")}
                </button>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ModifyPrinter;
