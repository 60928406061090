import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaPen, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import useSlider from "../../hooks/useSlider";
import { deleteLabel, getLabels } from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import Sidebar from "../sidebar/Sidebar";
import { toastAlert } from "../../utils/SweetAlert";

const LabelManagement = () => {
  const isSlider = useSlider();
  const navigate = useNavigate();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [showDelete, setShowDelete] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["category-list", { page, limit }],
    queryFn: async ({ queryKey }) => {
      let [_, params] = queryKey;
      const resp = await getLabels(params);
      return resp?.data?.data ?? [];
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteLabel(id),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  function modifyLabelSize(labelSize) {
    if (typeof labelSize !== "string") return labelSize;

    const parts = labelSize.split("X");
    const modifiedParts = parts.map((part) => {
      const trimmedPart = part.trim();
      if (!isNaN(trimmedPart) && trimmedPart !== "") {
        return `${trimmedPart}"`;
      }
      return trimmedPart;
    });
    return modifiedParts.join("X");
  }

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mainhead font-24">Label Management</h2>
          <div className="d-flex align-items-center gap-2">
            <Link to="../choose-size" className="userBtn btn-theme">
              Create Label
            </Link>
          </div>
        </div>

        <div className="tableOuter mt-4">
          <Row>
            {data?.labels && data?.labels?.length > 0 ? (
              data?.labels?.map((item, index) => {
                return (
                  <Col className="labelOuter" xl={3} key={index}>
                    <div className="labelbox">
                      <div className="labelImg mb-2">
                        <img
                          src={item?.label_image}
                          className="img-fluid labelImg-preview"
                        ></img>
                      </div>
                      <p className=" labelFooter font-16 colorDark mb-2">
                        {`${modifyLabelSize(item?.labelSize?.name)} ${
                          item?.labelSize?.metaData?.sizeInfo
                        }`}
                      </p>
                      <div className="d-flex align-items-center gap-2  justify-content-center">
                        <FaPen
                          color="#3a883a"
                          size={15}
                          role="button"
                          onClick={() =>
                            navigate(`../create-label?id=${item?._id}`, {
                              state: item?.labelSize,
                            })
                          }
                        />
                        <FaTrash
                          color="red"
                          size={15}
                          role="button"
                          onClick={() => setShowDelete(item?._id)}
                        />
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <Col md={12}>
                <h4 className="font-16 colorDark text-center">
                  No Label Found
                </h4>
              </Col>
            )}
          </Row>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.labels?.length}
        />
      </div>

      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default LabelManagement;
