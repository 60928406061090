import React, { useRef } from "react";
import { CrossIcon } from "../../../SvgIcons/allIcons";

const TextField = ({
  element,
  preview,
  setItem,
  handleChange,
  handleRemoveObject,
  setSelectedElement,
  selectedElement,
}) => {
  const textareaRef = useRef(null);
  let inputProps = {
    onChange: (e) => handleChange(e.target.value, element.id),
    value: element.value || "",
    readOnly: element.readOnly || false,
  };

  const handleInput = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };
  return (
    <div
      className="position-relative"
      onClick={() => setSelectedElement(element)}
    >
      {preview ? (
        <div className="label-input-preview d-flex gap-1">
          {element.showLabel && (
            <label className="labelMain" {...element.input}>
              {`${element.labelText}:`}
            </label>
          )}
          <p className="commonInput" {...element.input}>
            {element.value || ""}
          </p>
        </div>
      ) : (
        <div className="label-input">
          <span className="d-flex gap-1">
            {element.key == "additionalInfo" &&
            element.childKey == "customFieldValue" ? (
              <>
                <textarea
                  ref={textareaRef}
                  onInput={handleInput}
                  rows={1}
                  style={{
                    overflow: "hidden",
                    resize: "none",
                    ...element.input.style,
                  }}
                  value={element.labelText || ""}
                  onChange={(e) =>
                    setItem((prevItems) =>
                      prevItems.map((item) =>
                        item.id === element.id
                          ? { ...item, labelText: e.target.value }
                          : item
                      )
                    )
                  }
                  className="commonInput"
                  name="taskDate"
                  placeholder={element.placeholder || ""}
                />{" "}
                <p className="labelMain" {...element.input}>
                  :
                </p>
              </>
            ) : (
              element.showLabel && (
                <p className="labelMain" {...element.input}>
                  {`${element.labelText}:`}
                </p>
              )
            )}
            <textarea
              ref={textareaRef}
              onInput={handleInput}
              rows={1}
              style={{
                overflow: "hidden",
                resize: "none",
                ...element.input.style,
              }}
              className="commonInput"
              name="taskDate"
              placeholder={element.placeholder || ""}
              {...inputProps}
            />
            {selectedElement?.id == element.id && (
              <span
                className="cross-icon-label ms-1"
                role="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveObject(element.id);
                  setSelectedElement("");
                }}
              >
                <CrossIcon size={13} />
              </span>
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default TextField;
