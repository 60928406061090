import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NavigateBack from "../../components/NavigateBack";
import { getLabelSizes } from "../../services/services";
import { constant } from "../../utils/constants";
import Sidebar from "../sidebar/Sidebar";
import { toastAlert } from "../../utils/SweetAlert";

const ChooseSize = () => {
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [limit, setLimit] = useState(constant.PER_PAGE_TWENTY);
  const { t } = useTranslation();
  const [size, setSize] = useState("");

  const { data, refetch } = useQuery({
    queryKey: ["label-sizes-list", page, limit],
    queryFn: async () => {
      const resp = await getLabelSizes(page, limit);
      return resp?.data?.data ?? [];
    },
  });

  return (
    <div className="mainbox">
      <Sidebar sideBar={true} showFullHeader={true} />
      <div className={"body-content close aa"}>
        <Row>
          <Col xl={6}>
            <NavigateBack>Choose Size</NavigateBack>
          </Col>
          <Col className="text-end" xl={6}>
            <Link
              to="../create-label"
              state={size}
              onClick={(e) => {
                if (!size?._id) {
                  e.preventDefault();
                  toastAlert("error", "Please select label size");
                }
              }}
              className="userBtn btn-theme"
            >
              Create
            </Link>
          </Col>
        </Row>

        <Row className="mt-5">
          {data?.datalist && data?.datalist?.length > 0 ? (
            data.datalist.map((sizeData, sizeIndex) => (
              <Col
                md={2}
                className="my-2"
                key={`labelSize_${sizeIndex}`}
                onClick={() => setSize(sizeData)}
                role="button"
              >
                <div className="grid">
                  <div
                    className={`labelCard ${
                      size?._id == sizeData?._id && "labelCardactive"
                    }`}
                  >
                    <div className="labelContent">
                      <h3 className="heading colorGreen text-center">
                        {sizeData.name
                          ?.split("X")
                          ?.map((i) => `${i}"`)
                          ?.join("X") || "--"}
                      </h3>
                      <p className="font-20 colorGreen text-center">
                        {sizeData?.metaData?.sizeInfo || "--"}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div>
              <div className="text-center">
                <h4>No size available</h4>
              </div>
            </div>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ChooseSize;
