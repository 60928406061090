const allowedKeys = [
  "Backspace",
  "Delete",
  "ArrowLeft",
  "ArrowRight",
  "Tab",
  "Space",
];

export const numOnly = (e) => {
  if (
    !/[0-9]/.test(e.key) && // Prevent non-numeric input
    !allowedKeys.includes(e.key)
  ) {
    e.preventDefault();
  }
};

export const noSpecialChars = (e) => {
  if (
    !/[a-zA-Z0-9]/.test(e.key) && // Prevent non-alphanumeric input
    !allowedKeys.includes(e.key)
  ) {
    e.preventDefault();
  }
};

export const decimalOnly = (e) => {
  const isDecimalPointAllowed =
    e.key === "." && e.target.value.includes(".") === false;

  if (
    !/[0-9]/.test(e.key) &&
    !isDecimalPointAllowed &&
    !allowedKeys.includes(e.key)
  ) {
    e.preventDefault();
  }
};

export const unitsArr = [
  { label: "Bag(s)", value: "bag" },
  { label: "Basket(s)", value: "basket" },
  { label: "Box(es)", value: "box" },
  { label: "Bushel(s)", value: "bushel" },
  { label: "Case(s)", value: "case" },
  { label: "Dozen", value: "dozen" },
  { label: "Each", value: "each" },
  { label: "Flat(s)", value: "flat" },
  { label: "Gallon(s)", value: "gallon" },
  { label: "Gram(s)", value: "gram" },
  { label: "Head(s)", value: "head" },
  { label: "Liter(s)", value: "liter" },
  { label: "Kg", value: "kg" },
  { label: "ml", value: "ml" },
  { label: "mg", value: "mg" },
  { label: "Ounce(s)", value: "ounce" },
  { label: "Piece(s)", value: "piece" },
  { label: "Pound(s)", value: "pound" },
  { label: "Rack(s)", value: "rack" },
  { label: "Tray(s)", value: "tray" },
];

export const validateImageFileType = (file) => {
  const allowedTypes = ["image/jpeg", "image/png"];
  return allowedTypes.includes(file.type);
};

/**
 * 
 * @param {*} blob
 * @param {*} fileName 
 */

export const downloadFile = (blob, fileName) => {
  const blobUrl = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName ;
  document.body.appendChild(link);
  link.click();
  
  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobUrl);
}