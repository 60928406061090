import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { CrossIcon } from "../../SvgIcons/allIcons";
import DropDown from "../../components/DropDown";
import { addPlan, getAllCompany, updatePlan } from "../../services/services";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";

const AddPlanModel = ({ show, setShow, refetch }) => {
  const { t } = useTranslation();
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      planName: "",
      price: "",
      interval: "",
      intervalCount: 1,
      assignAll: false,
      company: "",
      addOn: [],
    },
    validationSchema: yup.object().shape({
      planName: yup.string().required().label(t("payment.planName")).trim(),
      price: yup.number().required().min(0).label(t("payment.price")),
      interval: yup.string().required().label(t("payment.interval")),
      assignAll: yup.boolean(),
      company: yup.string().when("assignAll", {
        is: (value) => !value,
        then: () =>
          yup.object().shape({
            value: yup.string().required().label("company"),
          }),
      }),
      intervalCount: yup
        .number()
        .required()
        .min(1)
        .max(12)
        .label(t("payment.intervalCount")),
      addOn: yup.array().min(1).label(t("payment.addOn")),
    }),
    onSubmit: (values) => {
      let body = {
        name: values?.planName,
        interval: values?.interval,
        unitAmount: values?.price,
        companyId: values?.company?.value,
        intervalCount: values?.intervalCount,
        addOns: values?.addOn?.map((i) => i.value),
      };

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) =>
      show?._id ? updatePlan(show?._id, body) : addPlan(body),
    onSuccess: (resp) => {
      handleClose();
      refetch();
      toastAlert("success", resp?.data?.message);
    },
  });

  const loadCompany = async (search, loadedOptions, { page }) => {
    let resp = await getAllCompany(page, constant.PER_PAGE_TEN, search);
    let array = (await resp?.data?.data?.companies) ?? [];
    if (!company) {
      setCompany({
        value: array?.at(0)?._id,
        label: array?.at(0)?.name,
        domain: array?.at(0)?.uuid,
      });
    }
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
        domain: item?.uuid,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };
  const handleClose = () => {
    setShow(false);
    resetForm();
  };

  const loadAddOn = async () => {
    return {
      options: constant.ADDONS,
      hasMore: false,
    };
  };

  useEffect(() => {
    if (show?._id) {
      let addOns = constant.ADDONS?.filter((i) =>
        show?.addOns?.includes(i.value)
      );

      setValues({
        ...values,
        planName: show?.name,
        price: show?.unitAmount,
        interval: show?.interval,
        assignAll: show?.companyId ? false : true,
        company: {
          label: show?.companyDetails?.name,
          value: show?.companyDetails?._id,
        },
        addOn: addOns,
      });
    }
  }, [show?._id]);

  return (
    <Modal
      show={!!show}
      onHide={handleClose}
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Header className="newPlan_ModalHeader" closeButton={false}>
        <Modal.Title>
          {show?._id ? t("payment.editPlan") : t("payment.createNewPlan")}
        </Modal.Title>
        <Button variant="link" onClick={handleClose} className="close_Btn">
          <CrossIcon />
        </Button>
      </Modal.Header>

      <Modal.Body className="newPlan_ModalBody">
        <form action="">
          <div className="createPlanform_Child">
            <label htmlFor="planName" className="companyLabel">
              {t("payment.planName")} <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="planName"
              className="commonInput"
              placeholder={t("payment.enterPlanName")}
              name="planName"
              value={values?.planName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <small className="text-danger ms-1">
              {touched.planName && errors.planName}
            </small>
          </div>
          <div className="customer_Access mt-0 ms-2">
            <div className="access_Child mt-1">
              <div className="form-group">
                <input
                  type="checkbox"
                  id="assignAll"
                  name="assignAll"
                  value={values?.assignAll}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.checked) setFieldValue("company", "");
                  }}
                  onBlur={handleBlur}
                  checked={values?.assignAll == true}
                />
                <label htmlFor="assignAll">
                  <span className="fontSize14 ms-2">All Companies</span>
                </label>
              </div>
            </div>
          </div>
          {!values?.assignAll && (
            <div className="createPlanform_Child pb-0">
              <label htmlFor="planName" className="companyLabel">
                Company <span className="text-danger">*</span>
              </label>

              <DropDown
                placeholder={t("user.selectCompany")}
                id="company"
                loadOptions={loadCompany}
                isClearable={false}
                onChange={(e) => {
                  setFieldValue("company", e);
                }}
                onBlur={() => setFieldTouched("company", true)}
                defaultOptions={true}
                value={values?.company}
              />
              <small className="text-danger ms-1">
                {touched.company && errors.company?.value}
              </small>
            </div>
          )}

          <div className="createPlanform_Child">
            <label htmlFor="price" className="companyLabel">
              {t("payment.price")} <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              id="price"
              className="commonInput"
              placeholder={t("payment.enterPrice")}
              name="price"
              value={values?.price}
              min={1}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <small className="text-danger ms-1">
              {touched.price && errors.price}
            </small>
          </div>
          <div className="createPlanform_Child">
            <label htmlFor="interval" className="companyLabel">
              {t("payment.interval")} <span className="text-danger">*</span>
            </label>
            <select
              className="commonInput"
              name="interval"
              id="interval"
              value={values?.interval}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Select</option>
              <option value="week">{t("payment.week")}</option>
              <option value="month">{t("payment.month")}</option>
              <option value="year">{t("payment.year")}</option>
            </select>
            <small className="text-danger ms-1">
              {touched.interval && errors.interval}
            </small>
          </div>
          {/* <div className="createPlanform_Child">
            <label htmlFor="intervalCount" className="companyLabel">
              {t("payment.intervalCount")}{" "}
              <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              id="intervalCount"
              className="commonInput"
              placeholder={t("payment.enterIntervalCount")}
              name="intervalCount"
              value={values?.intervalCount}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <small className="text-danger ms-1">
              {touched.intervalCount && errors.intervalCount}
            </small>
          </div> */}
          <div className="createPlanform_Child">
            <label htmlFor="name" className="companyLabel">
              {t("payment.addOn")} <span className="text-danger">*</span>
            </label>
            <DropDown
              placeholder={"Add Ons"}
              loadOptions={loadAddOn}
              isClearable={false}
              isMulti={true}
              defaultOptions={true}
              value={values?.addOn}
              onChange={(e) => setFieldValue("addOn", e)}
              onBlur={() => setFieldTouched("addOn", true)}
            />
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer className="newPlan_ModalFooter">
        <Button
          variant="secondary"
          className="userBtn btn-theme planModalFooter_Btn"
          onClick={handleSubmit}
          type="button"
        >
          {show?._id ? t("payment.update") : t("payment.create")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPlanModel;
