import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import CompanyDropDown from "../../components/CompanyDropDown";
import FileModal from "../../components/FileModal";
import useSlider from "../../hooks/useSlider";
import {
  downloadItemExcel,
  exportItemExcel,
  getItems,
  handleImportItem,
} from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import Sidebar from "../sidebar/Sidebar";
import { FaDownload, FaFileExport, FaFileImport } from "react-icons/fa6";
import { toastAlert } from "../../utils/SweetAlert";
import { downloadFile } from "../../helper/helper";

const ItemsList = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const { state } = useLocation();
  const [company, setCompany] = useState(!!state ? state : "");
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [show, setShow] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["item-list", page, limit, company?.domain],
    queryFn: async () => {
      const resp =
        company?.domain && (await getItems(page, limit, company?.domain));
      return resp?.data?.data ?? [];
    },
  });

  const fileUploadMutation = useMutation({
    mutationFn: ({ formData, domain }) => handleImportItem(formData, domain),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      refetch();
    },
  });

  const handleAction = async (data) => {
    try {
      if (data?.type === constant.FILE_ACTION.IMPORT) {
        const formData = new FormData();
        formData.append("file", data?.file);
        fileUploadMutation.mutate({ formData, domain: data?.company?.domain });
      } else {
        const domain = data?.company?.domain;
        const response =
          data?.type === constant.FILE_ACTION.EXPORT
            ? await exportItemExcel(domain)
            : await downloadItemExcel(domain);

        downloadFile(response?.data, `${domain}_item.xlsx`);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setShow(false);
    }
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mainhead font-24">{t("item.items")}</h2>
          <div className="d-flex align-items-center gap-2">
            <CompanyDropDown setCompany={setCompany} company={company} />
            <button
              className="userBtn btn-theme me-2"
              onClick={() => {
                setShow({ company, type: constant.FILE_ACTION.DOWNLOAD });
              }}
              title="Download Sample"
            >
              <FaDownload />
            </button>

            <label
              className="userBtn btn-theme me-2"
              role="button"
              htmlFor="import-catergory"
              title="Import"
            >
              <FaFileImport />
            </label>
            <button
              className="userBtn btn-theme me-2"
              title="Export"
              onClick={() => {
                if (!data?.items?.length) {
                  toastAlert("error", "Category not found to export");
                  return;
                }
                setShow({ company, type: constant.FILE_ACTION.EXPORT });
              }}
            >
              <FaFileExport />
            </button>
            <input
              type="file"
              className="d-none"
              id="import-catergory"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(e) => {
                if (
                  e.target.files[0]?.type !==
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                  toastAlert("error", t("errorMsg.xlsxFormat"));
                  return;
                }
                setShow({
                  company,
                  type: constant.FILE_ACTION.IMPORT,
                  file: e.target.files[0],
                });
              }}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
          </div>
        </div>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>{t("item.itemName")}</th>
                <th>{t("item.itemNameSpanish")}</th>
                <th>{t("item.category")}</th>
                <th>{t("item.applyToLocation")}</th>
                <th>{t("item.itemSellPrice")}</th>
                <th>{t("item.expiration")}</th>
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.items && data?.items?.length > 0 ? (
                data?.items?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.en_name}</td>
                      <td>{item?.es_name ? item?.es_name : "N/A"}</td>
                      <td> {item?.categoryDetails?.en_name}</td>
                      <td>
                        {item?.assignAll
                          ? t("globals.allLocations")
                          : item?.locations?.map((i) => i.name)?.join(", ")}
                      </td>
                      <td>{item?.additionalInfo?.sellingPrice}</td>
                      <td>
                        <p className="greenBox">{`${item?.expirationTime?.days}d:${item?.expirationTime?.hours}h:${item?.expirationTime?.mins}m`}</p>
                      </td>
                      <td>
                        <Link
                          to={`../item-details/${item?._id}`}
                          state={company}
                        >
                          <img
                            src="/images/eye.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("item.notFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.items?.length}
        />
      </div>

      {!!show && (
        <FileModal
          show={show}
          handleClose={() => setShow(false)}
          handleAction={handleAction}
        />
      )}
    </div>
  );
};

export default ItemsList;
