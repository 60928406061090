export function convertToTitleCase(text) {
    // Split the text by uppercase letters
    const words = text.match(/[A-Z][a-z]*/g);
    if (!words) return '';

    // Convert each word to have the first letter uppercase
    const titleCase = words.map(word => 
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    // Join the words with spaces
    return titleCase.join(' ');
}