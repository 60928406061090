import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaDownload, FaFileExport, FaFileImport } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import CompanyDropDown from "../../components/CompanyDropDown";
import DeleteModal from "../../components/DeleteModal";
import FileModal from "../../components/FileModal";
import { downloadFile } from "../../helper/helper";
import useSlider from "../../hooks/useSlider";
import {
  deleteDataList,
  downloadDataListExcel,
  exportDataListExcel,
  getDataList,
  handleImportDataList,
} from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";

const PrepList = () => {
  const { state } = useLocation();
  const [company, setCompany] = useState(!!state ? state : "");
  const isSlider = useSlider();
  const { t } = useTranslation();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["data-list", page, limit, company?.domain],
    queryFn: async () => {
      const resp =
        company?.domain &&
        (await getDataList(
          page,
          limit,
          search,
          constant.DATA_LIST.PREP_LIST,
          company?.domain,
          true,
          true
        ));
      return resp?.data?.data ?? [];
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteDataList(id, company?.domain),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  const fileUploadMutation = useMutation({
    mutationFn: ({ formData, domain }) =>
      handleImportDataList(formData, domain, constant.DATA_LIST.PREP_LIST),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      refetch();
    },
  });

  const handleAction = async (data) => {
    try {
      if (data?.type === constant.FILE_ACTION.IMPORT) {
        const formData = new FormData();
        formData.append("file", data?.file);
        fileUploadMutation.mutate({ formData, domain: data?.company?.domain });
      } else {
        const domain = data?.company?.domain;
        const response =
          data?.type === constant.FILE_ACTION.EXPORT
            ? await exportDataListExcel(domain, constant.DATA_LIST.PREP_LIST)
            : await downloadDataListExcel(domain, constant.DATA_LIST.PREP_LIST);

        downloadFile(response?.data, `${domain}_prepList.xlsx`);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setShow(false);
    }
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mainhead font-24">{t("prepList.prepList")} </h2>
          <div className="d-flex align-items-center gap-2">
            <CompanyDropDown setCompany={setCompany} company={company} />
            <button
              className="userBtn btn-theme me-2"
              onClick={() => {
                setShow({ company, type: constant.FILE_ACTION.DOWNLOAD });
              }}
              title="Download Sample"
            >
              <FaDownload />
            </button>

            <label
              className="userBtn btn-theme me-2"
              role="button"
              htmlFor="import-catergory"
              title="Import"
            >
              <FaFileImport />
            </label>
            <button
              className="userBtn btn-theme me-2"
              title="Export"
              onClick={() => {
                if (!data?.datalists?.length) {
                  toastAlert("error", "Category not found to export");
                  return;
                }
                setShow({ company, type: constant.FILE_ACTION.EXPORT });
              }}
            >
              <FaFileExport />
            </button>
            <input
              type="file"
              className="d-none"
              id="import-catergory"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(e) => {
                if (
                  e.target.files[0]?.type !==
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                  toastAlert("error", t("errorMsg.xlsxFormat"));
                  return;
                }
                setShow({
                  company,
                  type: constant.FILE_ACTION.IMPORT,
                  file: e.target.files[0],
                });
              }}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
            <Link
              className="userBtn btn-theme"
              to="../add-prep-list"
              state={company}
            >
              {t("globals.addNew")}
            </Link>
          </div>
        </div>

        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>{t("prepList.productionName")}</th>
                <th>{t("prepList.items")}</th>
                <th>{t("prepList.qunatityLabel")}</th>
                <th>{t("prepList.labelProfile")}</th>
                {/* <th>{t("prepList.preppedQty")}</th> */}
                <th>{t("prepList.prepLocation")}</th>
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.datalists && data?.datalists?.length > 0 ? (
                data?.datalists?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>{item?.itemDetails?.en_name}</td>
                      <td>{item?.details?.labelQuantity}</td>
                      <td>
                        {item?.details?.labelProfile
                          ? item?.details?.labelProfile
                          : "N/A"}
                      </td>
                      {/* <td>
                          {item?.details?.prepQuantity
                            ? item?.details?.prepQuantity
                            : "N/A"}
                        </td> */}
                      <td>
                        {item?.assignAll
                          ? t("prepList.allLocations")
                          : item?.locations?.map((i) => i?.name)?.join(", ")}
                      </td>
                      <td>
                        <Link
                          to={`../add-prep-list?id=${item?._id}`}
                          state={company}
                          role="button"
                        >
                          <img src="/images/Edit.svg" alt="" />
                        </Link>

                        <img
                          src="/images/Delete.svg"
                          alt="delete"
                          onClick={() => setShowDelete(item?._id)}
                          role="button"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("prepList.noPrepList")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalPages={data?.total_pages}
            total={data?.total}
            length={data?.datalists?.length}
          />
        </div>
      </div>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
      <FileModal
        show={show}
        handleClose={() => setShow(false)}
        handleAction={handleAction}
      />
    </div>
  );
};

export default PrepList;
