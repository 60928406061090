import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Search } from "../../SvgIcons/allIcons";
import useSlider from "../../hooks/useSlider";
import { getAllCompany, getUser } from "../../services/services";
import Pagination from "../../utils/Pagination";
import { constant } from "../../utils/constants";
import Sidebar from "../sidebar/Sidebar";
import { noSpecialChars } from "../../helper/helper";

const UserList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [role, setRole] = useState("all");
  const isSlider = useSlider();
  const [company, setCompany] = useState("{}");
  let comp = JSON?.parse(company);

  const [brand, setBrand] = useState("");

  const { data, refetch } = useQuery({
    queryKey: ["user-list", page, limit, role, comp?.id, brand],
    queryFn: async () => {
      const resp = await getUser(page, limit, search, role, comp?.id, brand);
      return resp?.data?.data;
    },
  });

  const { data: companyList } = useQuery({
    queryKey: ["company-list"],
    queryFn: async () => {
      const resp = await getAllCompany(1, 50);
      return resp?.data?.data;
    },
  });

  const { data: brandList } = useQuery({
    queryKey: ["company-details", comp?.domain],
    queryFn: async () => {
      const resp = comp?.domain && (await getCompanyBrands(comp?.domain));
      return resp?.data?.data ?? [];
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6}>
            <h2 className="mainhead font-24">{t("user.userManagement")}</h2>
          </Col>
          {/* <Col className="text-end" xl={6}>
            <Link className="userBtn btn-theme" to="">
              {t("globals.export")}
            </Link>
          </Col> */}
        </Row>
        <Row className="align-items-center">
          <Col md={8}>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center me-3">
                <label className="labelText">{t("user.role")}:</label>
                <select
                  className="form-select selectfeild"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="all">{t("user.all")}</option>
                  <option value="admin">{t("user.admin")}</option>
                  <option value="manager">{t("user.manager")}</option>
                  <option value="user">{t("user.user")}</option>
                </select>
              </div>
              <div className="d-flex align-items-center me-3">
                <label className="labelText text-nowrap">
                  {t("user.company")}:
                </label>
                <select
                  className="form-select selectfeild"
                  value={company}
                  onChange={(e) => {
                    setCompany(e.target.value);
                    setBrand("");
                  }}
                >
                  {companyList?.companies &&
                  companyList?.companies?.length > 0 ? (
                    <>
                      <option value="{}">{t("user.all")}</option>
                      {companyList?.companies?.map((item, index) => {
                        return (
                          <option
                            value={JSON.stringify({
                              id: item?._id,
                              domain: item?.uuid,
                            })}
                            key={index}
                          >
                            {item?.name}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <option value="{}">{t("user.noOptions")}</option>
                  )}
                </select>
              </div>
              <div className="d-flex align-items-center me-3">
                <label className="labelText text-nowrap">
                  {t("user.brand")}:
                </label>
                <select
                  className="form-select selectfeild"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                >
                  {brandList?.associatedBrands &&
                  brandList?.associatedBrands?.length > 0 ? (
                    <>
                      <option value="">{t("user.all")}</option>
                      {brandList?.associatedBrands?.map((item, index) => {
                        return (
                          <option value={item?.brand?._id} key={index}>
                            {item?.brand?.name}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <option value="">{t("user.noOptions")}</option>
                  )}
                </select>
              </div>
            </div>
          </Col>
          <Col md={4} className="py-4">
            <div className="sidebarBox">
              <div className="sidebar-box search-form-wrap mb-0">
                <div className="search-form">
                  <div className="form-group">
                    <span className="iconsearch">
                      <Search />
                    </span>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={(e) => {
                        noSpecialChars(e);
                        if (e.key == "Enter" && search.trim() !== "") refetch();
                      }}
                      onKeyUp={(e) =>
                        e.target.value == "" &&
                        e.key == "Backspace" &&
                        refetch()
                      }
                      type="text"
                      className="form-control"
                      placeholder={t("globals.search")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="table-responsive tableOut">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th>{t("user.custId")}</th>
                <th>{t("user.name")}</th>
                <th>{t("user.emailAddress")} </th>
                <th>{t("user.role")}</th>
                <th>{t("user.added")} </th>
                <th>{t("globals.action")} </th>
              </tr>
            </thead>
            <tbody>
              {data?.users && data?.users?.length > 0 ? (
                data?.users?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{(page - 1) * limit + (index + 1)}</td>
                      <td>{item?.userId}</td>
                      <td className="text-capitalize">
                        {item?.userInfo?.name}
                      </td>
                      <td>{item?.email}</td>
                      <td className="text-capitalize">{item?.role}</td>
                      <td>{moment(item?.createdAt).format("lll")}</td>
                      <td>
                        <Link to={`../user/${item?._id}`}>
                          <img
                            src="/images/eye.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("user.notFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.users?.length}
        />
      </div>
    </div>
  );
};

export default UserList;
