import React from "react";
import { useDrag } from "react-dnd";

const DraggableItem = ({ children, data }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "ITEM",
    item: { data: data },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
    >
      {children}
    </div>
  );
};

export default DraggableItem;
