import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Stepper } from "react-form-stepper";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import * as yup from "yup";
import DeleteModal from "../../components/DeleteModal";
import Loader from "../../components/Loader/Loader";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import {
  addBrandLocation,
  deleteCompanyBrand,
  deleteCompanyLocation,
  getCompanyBrands,
  getCompanyLocation,
  updateBrandLocation,
} from "../../services/services";
import { toastAlert } from "../../utils/SweetAlert";
import AdminFooter from "../AdminFooter";
import Sidebar from "../sidebar/Sidebar";
import BrandModal from "./BrandModal";
import "./companyManagement.css";

const CompanyLocation = () => {
  const { header } = useParams();
  const navigate = useNavigate();
  const isSlider = useSlider();
  const { t } = useTranslation();
  const [searchParam] = useSearchParams();
  const id = searchParam?.get("id");
  const [showDelete, setShowDelete] = useState(false);
  const [showBrandModal, setShowBrandModal] = useState(false);

  const handleBack = () => {
    navigate(`../addnewcompany?header=${header}&id=${id}`);
  };

  const {
    values,
    handleChange,
    handleBlur,
    resetForm,
    touched,
    errors,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: {
      id: "",
      locationName: "",
      sameAddress: false,
      billing: {
        address: "",
        streeAddress: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      },
      device: {
        address: "",
        streeAddress: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      },
      deviceId: "",
      printerSerial: "",
      printerModel: "",
      tracking: "",
    },
    validationSchema: yup.object().shape({
      locationName: yup
        .string()
        .required()
        .label(t("addCompany.locationName"))
        .trim()
        .min(1),
      billing: yup.object().shape({
        address: yup
          .string()
          .required()
          .label(t("addCompany.address"))
          .trim()
          .min(1),
        // streeAddress: yup
        //   .string()
        //   .required()
        //   .label(t("addCompany.streetAddress"))
        //   .trim()
        //   .min(1),
        city: yup.string().required().label(t("addCompany.city")).trim().min(1),
        state: yup
          .string()
          .required()
          .label(t("addCompany.state"))
          .trim()
          .min(1),
        country: yup
          .string()
          .required()
          .label(t("addCompany.country"))
          .trim()
          .min(1),
        zipCode: yup
          .string()
          .required()
          .label(t("addCompany.zipCode"))
          .matches(/^[a-zA-Z0-9]*$/, t("errorMsg.invalidZipCode"))
          .trim(),
      }),
      sameAddress: yup.boolean(),
      device: yup.object().shape({
        address: yup.string().when(["$sameAddress"], {
          is: (value) => !value,
          then: () =>
            yup
              .string()
              .required()
              .label(t("addCompany.address"))
              .trim()
              .min(1),
        }),
        // streeAddress: yup.string().when(["$sameAddress"], {
        //   is: (value) => !value,
        //   then: () =>
        //     yup
        //       .string()
        //       .required()
        //       .label(t("addCompany.streetAddress"))
        //       .trim()
        //       .min(1),
        // }),

        city: yup.string().when(["$sameAddress"], {
          is: (value) => !value,
          then: () =>
            yup.string().required().label(t("addCompany.city")).trim().min(1),
        }),
        state: yup.string().when(["$sameAddress"], {
          is: (value) => !value,
          then: () =>
            yup.string().required().label(t("addCompany.state")).trim().min(1),
        }),
        country: yup.string().when(["$sameAddress"], {
          is: (value) => !value,
          then: () =>
            yup
              .string()
              .required()
              .label(t("addCompany.country"))
              .trim()
              .min(1),
        }),
        zipCode: yup.string().when(["$sameAddress"], {
          is: (value) => !value,
          then: () =>
            yup
              .string()
              .required()
              .label(t("addCompany.zipCode"))
              .matches(/^[a-zA-Z0-9]*$/, t("errorMsg.invalidZipCode"))
              .trim(),
        }),
      }),
      deviceId: yup
        .string()
        .required()
        .label(t("addCompany.deviceId"))
        .trim()
        .max(15)
        .min(1),
      printerSerial: yup
        .string()
        // .required()
        .label(t("addCompany.printerSerialNumber"))
        .trim()
        .max(15)
        .min(1),
      printerModel: yup
        .string()
        .required()
        .label(t("addCompany.printerModel"))
        .trim()
        .max(15)
        .min(1),
      tracking: yup
        .string()
        // .required()
        .label(t("addCompany.trackingNumber"))
        .trim()
        .max(15)
        .min(1),
    }),
    onSubmit: (values) => {
      let body = {
        name: values?.locationName,
        billingAddress: {
          fullAddress: values?.billing?.address,
          street: values?.billing?.streeAddress,
          city: values?.billing?.city,
          state: values?.billing?.state,
          country: values?.billing?.country,
          zipCode: values?.billing?.zipCode,
          lat: 30.6575339,
          long: 76.7269559,
        },
        deviceAddress: {
          fullAddress: values?.device?.address,
          street: values?.device?.streeAddress,
          city: values?.device?.city,
          state: values?.device?.state,
          country: values?.device?.country,
          zipCode: values?.device?.zipCode,
          lat: 30.6575339,
          long: 76.7269559,
        },
        deviceInfo: {
          deviceId: values?.deviceId,
          serialNumber: values?.printerSerial,
          trackingNumber: values?.tracking,
          model: values?.printerModel,
        },
        sameAsBillingAddress: values?.sameAddress,
      };

      if (values?.sameAddress) {
        body.deviceAddress = {
          fullAddress: values?.billing?.address,
          street: values?.billing?.streeAddress,
          city: values?.billing?.city,
          state: values?.billing?.state,
          country: values?.billing?.country,
          zipCode: values?.billing?.zipCode,
          lat: 30.6575339,
          long: 76.7269559,
        };
      }

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) =>
      values?.id
        ? updateBrandLocation(values?.id, header, body)
        : addBrandLocation(header, body),
    onSuccess: (resp) => {
      refetch();
      resetForm();
      refetchLocation();
      toastAlert("success", resp?.data?.message);
    },
  });

  const { data: brandData, refetch } = useQuery({
    queryKey: ["company-brand-list", header],
    queryFn: async () => {
      let resp = await getCompanyBrands(header);
      return resp?.data?.data;
    },
  });

  const { data: locations, refetch: refetchLocation } = useQuery({
    queryKey: ["company-locations", header],
    queryFn: async () => {
      let resp = await getCompanyLocation(header);
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) =>
      id?.locationId
        ? deleteCompanyLocation(id?.locationId, header)
        : deleteCompanyBrand(id, header),
    onSuccess: (resp) => {
      refetch();
      refetchLocation();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  const handleEdit = (location) => {
    setValues({
      ...values,
      id: location?._id,
      locationName: location?.name,
      billing: {
        address: location?.billingAddress?.fullAddress,
        streeAddress: location?.billingAddress?.street,
        city: location?.billingAddress?.city,
        state: location?.billingAddress?.state,
        country: location?.billingAddress?.country,
        zipCode: location?.billingAddress?.zipCode,
      },
      device: {
        address: location?.deviceAddress?.fullAddress,
        streeAddress: location?.deviceAddress?.street,
        city: location?.deviceAddress?.city,
        state: location?.deviceAddress?.state,
        country: location?.deviceAddress?.country,
        zipCode: location?.deviceAddress?.zipCode,
      },
      deviceId: location?.deviceInfo?.deviceId,
      printerSerial: location?.deviceInfo?.serialNumber,
      printerModel: location?.deviceInfo?.model,
      tracking: location?.deviceInfo?.trackingNumber,
      sameAddress: location?.sameAsBillingAddress,
    });
  };

  const handleNextClick = () => {
    if (
      values?.brand?.value ||
      values?.locationName ||
      values?.billing?.address ||
      values?.billing?.streeAddress ||
      values?.billing?.city ||
      values?.billing?.state ||
      values?.billing?.country ||
      values?.billing?.zipCode ||
      values?.device?.address ||
      values?.device?.streeAddress ||
      values?.device?.city ||
      values?.device?.state ||
      values?.device?.country ||
      values?.device?.zipCode ||
      values?.deviceId ||
      values?.printerSerial ||
      values?.printerModel ||
      values?.tracking
    ) {
      return toastAlert("error", t("errorMsg.addLocationToProceed"));
    } else {
      return navigate("../companymanagement");
    }
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6}>
            <NavigateBack handleClick={() => handleBack()}>
              {" "}
              {t("addCompany.addNewCompany")}{" "}
            </NavigateBack>
          </Col>
        </Row>
        <div className="basic_Info company-info">
          <Stepper
            steps={[
              { label: t("addCompany.basicInfo"), children: <FaCheck /> },
              { label: t("addCompany.brandLocation") },
            ]}
            activeStep={1}
            className="ffff"
          />

          <div className="mb-4 companyLocInfo">
            <div className="d-flex align-items-center justify-content-between">
              <p className="font-20 colorDark">
                {t("addCompany.allLocations")}
              </p>
              <button
                className="userBtn btn-theme"
                onClick={() => setShowBrandModal(true)}
                type="button"
              >
                {t("addCompany.associateBrand")}
              </button>
            </div>
            <div className="table-responsive companyManagTable tableOut mt-4">
              <table className="table tableDashboard">
                <thead className="thead-dark">
                  <tr>
                    <th> {t("addCompany.name")}</th>
                    <th>{t("addCompany.location")}</th>
                    <th>{t("globals.action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {locations?.locations?.locations &&
                  locations?.locations?.locations?.length > 0 ? (
                    locations?.locations?.locations?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data?.name}</td>
                          <td>{data?.billingAddress?.fullAddress}</td>
                          <td>
                            <Link to={`../location/${data?._id}/${header}`}>
                              <img
                                src="/images/eye.svg"
                                className="img-fluid actionIcon"
                              />
                            </Link>

                            <img
                              src="/images/Edit.svg"
                              className="img-fluid actionIcon"
                              onClick={() => handleEdit(data)}
                            />
                            <img
                              src="/images/Delete.svg "
                              className="img-fluid actionIcon"
                              onClick={() =>
                                setShowDelete({ locationId: data?._id })
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        <h4>{t("brand.locationNotFound")}</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {brandData?.associatedBrands &&
            brandData?.associatedBrands?.length > 0 &&
            brandData?.associatedBrands?.map((item, index) => {
              return (
                <div className="mb-4 companyLocInfo" key={index}>
                  <div className="d-flex align-items-center">
                    <p className="font-20 colorDark">
                      {t("addCompany.brandName")}:
                      <span className="colorLight ms-2">
                        {item?.brand?.name}
                      </span>
                    </p>
                    <div className="ms-auto">
                      <Link
                        className="editBrand me-1"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowBrandModal(item);
                        }}
                      >
                        <img src="/images/Edit.svg" alt="Edit Brand" />
                      </Link>
                      <Link
                        className="deleteBrand"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowDelete(item?._id);
                        }}
                      >
                        <img src="/images/Delete.svg" alt="delete" />
                      </Link>
                    </div>
                  </div>
                  <div className="table-responsive companyManagTable tableOut mt-4">
                    <table className="table tableDashboard">
                      <thead className="thead-dark">
                        <tr>
                          <th> {t("addCompany.name")}</th>
                          <th>{t("addCompany.location")}</th>
                          <th>{t("globals.action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.locations && item?.locations?.length > 0 ? (
                          item?.locations?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data?.name}</td>
                                <td>{data?.billingAddress?.fullAddress}</td>
                                <td>
                                  <Link
                                    to={`../location/${data?._id}/${header}`}
                                  >
                                    <img
                                      src="/images/eye.svg"
                                      className="img-fluid actionIcon"
                                    />
                                  </Link>

                                  <img
                                    src="/images/Edit.svg"
                                    className="img-fluid actionIcon"
                                    onClick={() => handleEdit(data)}
                                  />
                                  <img
                                    src="/images/Delete.svg "
                                    className="img-fluid actionIcon"
                                    onClick={() =>
                                      setShowDelete({ locationId: data?._id })
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10} className="text-center">
                              <h4>{t("brand.locationNotFound")}</h4>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}

          <div className="mt- companyLocInfo">
            <Form>
              <div className="companyAddLoc mt-3">
                <Row>
                  <Col md={12}>
                    <div>
                      <label htmlFor="locationName" className="companyLabel">
                        {t("addCompany.locationName")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="locationName"
                        className="commonInput"
                        placeholder={t("addCompany.enterLocationName")}
                        name="locationName"
                        value={values?.locationName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger ms-1">
                        {touched.locationName && errors.locationName}
                      </small>
                    </div>
                  </Col>
                </Row>
                <div>
                  <h3 className="infoTxt fontSize16">
                    {t("addCompany.billingAddress")}
                  </h3>
                  <Row>
                    <Col md={6}>
                      <div className="mt-2">
                        <label
                          htmlFor="locationAddress"
                          className="companyLabel"
                        >
                          {t("addCompany.address")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="locationAddress"
                          className="commonInput"
                          placeholder={t("addCompany.enterAddress")}
                          name="billing.address"
                          value={values?.billing?.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <small className="text-danger ms-1">
                          {touched?.billing?.address &&
                            errors?.billing?.address}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-2">
                        <label htmlFor="streeAddress" className="companyLabel">
                          {t("addCompany.streetAddress")}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input
                          type="text"
                          id="streeAddress"
                          className="commonInput"
                          placeholder={t("addCompany.enterStreetAddress")}
                          name="billing.streeAddress"
                          value={values?.billing?.streeAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <small className="text-danger ms-1">
                          {touched.billing?.streeAddress &&
                            errors.billing?.streeAddress}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-2">
                        <label htmlFor="city" className="companyLabel">
                          {t("addCompany.city")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="city"
                          className="commonInput"
                          placeholder={t("addCompany.enterCity")}
                          name="billing.city"
                          value={values?.billing?.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <small className="text-danger ms-1">
                          {touched.billing?.city && errors.billing?.city}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-3">
                        <label htmlFor="state" className="companyLabel">
                          {t("addCompany.state")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="state"
                          className="commonInput"
                          placeholder={t("addCompany.enterState")}
                          name="billing.state"
                          value={values?.billing?.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <small className="text-danger ms-1">
                          {touched.billing?.state && errors.billing?.state}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-3">
                        <label htmlFor="country" className="companyLabel">
                          {t("addCompany.country")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="country"
                          className="commonInput"
                          placeholder={t("addCompany.enterCountry")}
                          name="billing.country"
                          value={values?.billing?.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <small className="text-danger ms-1">
                          {touched.billing?.country && errors.billing?.country}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-3">
                        <label htmlFor="zipCode" className="companyLabel">
                          {t("addCompany.zipCode")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="zipCode"
                          className="commonInput"
                          placeholder={t("addCompany.enterZipCode")}
                          name="billing.zipCode"
                          value={values?.billing?.zipCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={10}
                        />
                        <small className="text-danger ms-1">
                          {touched.billing?.zipCode && errors.billing?.zipCode}
                        </small>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="customer_Access mt-2">
                  <h3 className="infoTxt fontSize16">
                    {t("addCompany.deviceAddress")}
                  </h3>
                  <div className="form-group mb-0 mt-2">
                    <input
                      type="checkbox"
                      id="html23"
                      name="sameAddress"
                      value={values?.sameAddress}
                      checked={values?.sameAddress == true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="html23">
                      <span className="fontSize14 ms-2">
                        {t("addCompany.sameAsBilling")}
                      </span>
                    </label>
                  </div>
                  <Row>
                    <Col md={6}>
                      <div className="mt-2">
                        <label
                          htmlFor="locationAddress1"
                          className="companyLabel"
                        >
                          {t("addCompany.address")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="locationAddress1"
                          className="commonInput"
                          placeholder={t("addCompany.enterAddress")}
                          name="device.address"
                          disabled={values?.sameAddress}
                          value={
                            values?.sameAddress
                              ? values?.billing?.address
                              : values?.device?.address
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <small className="text-danger ms-1">
                          {touched?.device?.address && errors?.device?.address}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-2">
                        <label htmlFor="streeAddress1" className="companyLabel">
                          {t("addCompany.streetAddress")}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input
                          type="text"
                          id="streeAddress1"
                          className="commonInput"
                          placeholder={t("addCompany.enterStreetAddress")}
                          name="device.streeAddress"
                          value={
                            values?.sameAddress
                              ? values?.billing?.streeAddress
                              : values?.device?.streeAddress
                          }
                          disabled={values?.sameAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <small className="text-danger ms-1">
                          {touched.device?.streeAddress &&
                            errors.device?.streeAddress}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-2">
                        <label htmlFor="city1" className="companyLabel">
                          {t("addCompany.city")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="city1"
                          className="commonInput"
                          placeholder={t("addCompany.enterCity")}
                          name="device.city"
                          disabled={values?.sameAddress}
                          value={
                            values?.sameAddress
                              ? values?.billing?.city
                              : values?.device?.city
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <small className="text-danger ms-1">
                          {touched.device?.city && errors.device?.city}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-3">
                        <label htmlFor="state1" className="companyLabel">
                          {t("addCompany.state")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="state1"
                          className="commonInput"
                          placeholder={t("addCompany.enterState")}
                          name="device.state"
                          disabled={values?.sameAddress}
                          value={
                            values?.sameAddress
                              ? values?.billing?.state
                              : values?.device?.state
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <small className="text-danger ms-1">
                          {touched.device?.state && errors.device?.state}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-3">
                        <label htmlFor="country1" className="companyLabel">
                          {t("addCompany.country")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="country1"
                          className="commonInput"
                          placeholder={t("addCompany.enterState")}
                          name="device.country"
                          disabled={values?.sameAddress}
                          value={
                            values?.sameAddress
                              ? values?.billing?.country
                              : values?.device?.country
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <small className="text-danger ms-1">
                          {touched.device?.country && errors.device?.country}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-3">
                        <label htmlFor="zipCode1" className="companyLabel">
                          {t("addCompany.zipCode")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="zipCode1"
                          className="commonInput"
                          placeholder={t("addCompany.enterZipCode")}
                          name="device.zipCode"
                          disabled={values?.sameAddress}
                          value={
                            values?.sameAddress
                              ? values?.billing?.zipCode
                              : values?.device?.zipCode
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={10}
                        />
                        <small className="text-danger ms-1">
                          {touched.device?.zipCode && errors.device?.zipCode}
                        </small>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div>
                  <h3 className="infoTxt fontSize16">
                    {t("addCompany.deviceInfo")}
                  </h3>
                  <Row>
                    <Col md={6}>
                      <div className="mt-2">
                        <label htmlFor="deviceId" className="companyLabel">
                          {t("addCompany.deviceId")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="deviceId"
                          className="commonInput"
                          value={values?.deviceId}
                          name="deviceId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("addCompany.enterDeviceId")}
                        />
                        <small className="text-danger ms-1">
                          {touched.deviceId && errors.deviceId}
                        </small>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mt-3">
                        <label htmlFor="printerModel" className="companyLabel">
                          {t("addCompany.printerModel")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="printerModel"
                          className="commonInput"
                          name="printerModel"
                          value={values?.printerModel}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("addCompany.enterModel")}
                        />
                        <small className="text-danger ms-1">
                          {touched.printerModel && errors.printerModel}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-2">
                        <label htmlFor="printerSerial" className="companyLabel">
                          {t("addCompany.printerSerialNumber")}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input
                          type="text"
                          id="printerSerial"
                          className="commonInput"
                          value={values?.printerSerial}
                          name="printerSerial"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t("addCompany.enterPrinterSerialNumber")}
                        />
                        <small className="text-danger ms-1">
                          {touched.printerSerial && errors.printerSerial}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mt-3">
                        <label htmlFor="tracking" className="companyLabel">
                          {t("addCompany.trackingNumber")}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input
                          type="text"
                          id="tracking"
                          value={values?.tracking}
                          name="tracking"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="commonInput"
                          placeholder={t("addCompany.enterTrackingNumber")}
                        />
                        <small className="text-danger ms-1">
                          {touched.tracking && errors.tracking}
                        </small>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mt-4 d-flex align-items-center justify-content-center gap-2 ">
                  <button
                    className="addLocBtn fontSize18"
                    type="button"
                    onClick={handleSubmit}
                  >
                    {values?.id
                      ? t("addCompany.updateLocation")
                      : t("addCompany.addLocation")}
                  </button>

                  {values?.id && (
                    <button
                      className="addLocBtn fontSize18"
                      type="button"
                      onClick={resetForm}
                    >
                      {t("addCompany.cancel")}
                    </button>
                  )}
                </div>
              </div>
              {/* <button
                className="userBtn btn-theme mt-3 d-block mx-auto fontSize18"
                type="button"
              >
                {t("addCompany.addBrand")}
              </button> */}
            </Form>
          </div>
          <div className="btnGroup mt-4 d-flex ">
            <button
              className="addLocBtn fontSize18"
              type="button"
              onClick={handleBack}
            >
              {t("addCompany.back")}
            </button>

            <button
              className="userBtn btn-theme ms-auto  fontSize18"
              type="button"
              onClick={() => {
                handleNextClick();
              }}
            >
              {t("addCompany.next")}
            </button>
          </div>
        </div>
      </div>
      <AdminFooter />
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />

      <BrandModal
        header={header}
        show={showBrandModal}
        brandData={brandData}
        refetch={refetch}
        handleClose={() => setShowBrandModal(false)}
      />

      {mutation.isPending && <Loader />}
    </div>
  );
};

export default CompanyLocation;
