import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import moment from "moment";
import React, { useCallback, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import DropDown from "../../components/DropDown";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import {
  addDataList,
  addTaskImage,
  getAllCompany,
  getCompanyLocation,
  getDataListbyId,
  getUser,
  updateDataList,
} from "../../services/services";
import { UploadIcon } from "../../SvgIcons/allIcons";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";

const AddNewTask = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const id = searchParam.get("id");
  const isSlider = useSlider();
  const { t } = useTranslation();

  const startDateRef = useRef();
  const endDateRef = useRef();
  const dateRef = useRef();

  const onDrop = useCallback((e) => {
    setFieldValue("picture", e[0]);
  }, []);

  const onDropRejected = useCallback((e) => {
    toastAlert("error", t("errorMsg.fileFormat"));
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: { "image/*": [] },
    multiple: false,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldTouched,
    setValues,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      company: "",
      taskListName: "",
      taskName: "",
      locations: [],
      assignAll: false,
      startCheck: "false",
      startTime: "",
      endCheck: "false",
      endTime: "",
      taskDate: "",
      specificCheck: "false",
      specificEmployee: "",
      dailyTask: "false",
      pictureRequired: "false",
      picture: "",
      image: "",
    },
    validationSchema: yup.object().shape({
      company: yup.object().shape({
        value: yup.string().required().label(t("product.company")),
      }),
      taskListName: yup.string().required().label(t("task.taskListName")),
      taskName: yup.string().required().label(t("task.taskName")),
      locations: yup.array().min(1).label(t("task.taskLocation")),
      startTime: yup.string().nullable(),
      endTime: yup
        .string()
        .nullable()
        .test(
          "is-greater",
          "End time must be later than start time",
          function (value) {
            const { startTime } = this.parent;

            // Only validate if both startTime and endTime are provided
            if (startTime && value) {
              return (
                new Date(`1970-01-01T${value}`) >
                new Date(`1970-01-01T${startTime}`)
              );
            }

            return true; // If one or both are empty, skip validation
          }
        ),
      taskDate: yup.string().when("dailyTask", {
        is: (value) => value === "false",
        then: () => yup.string().required().label(t("task.taskDate")),
      }),
    }),
    onSubmit: (values) => {
      if (!!values?.picture) {
        let formData = new FormData();
        formData.append("file", values?.picture);
        imageMutation.mutate(formData);
      } else {
        let body = {
          name: values?.taskListName,
          type: constant.DATA_LIST.TASK_LIST,
          customerId: values?.specificEmployee?.value,
          assignAll: values?.assignAll,
          details: {
            taskName: values?.taskName,
            dailyTask: values?.dailyTask == "false" ? false : true,
            pictureRequired: values?.pictureRequired,
          },
        };
        if (!!id && values?.image) {
          body.details.image = values?.image;
        }
        if (!values?.assignAll)
          body.locations = values?.locations?.map((i) => i.value);
        if (values?.startTime) {
          body.details.startTime = moment(values?.startTime, "HH:mm").format(
            "LT"
          );
        }
        if (values?.endTime)
          body.details.endTime = moment(values?.endTime, "HH:mm").format("LT");

        if (values?.taskDate)
          body.details.taskDate = moment(values?.taskDate)?.format();

        mutation.mutate(body);
      }
    },
  });

  const imageMutation = useMutation({
    mutationFn: (body) => addTaskImage(body),
    onSuccess: (resp) => {
      let body = {
        name: values?.taskListName,
        type: constant.DATA_LIST.TASK_LIST,
        customerId: values?.specificEmployee?.value,
        assignAll: values?.assignAll,
        details: {
          taskName: values?.taskName,
          dailyTask: values?.dailyTask == "false" ? false : true,
          image: resp?.data?.data?.imageUrl,
          pictureRequired: values?.pictureRequired,
        },
      };
      if (!values?.assignAll)
        body.locations = values?.locations?.map((i) => i.value);
      if (values?.startTime) {
        body.details.startTime = moment(values?.startTime, "HH:mm").format(
          "LT"
        );
      }
      if (values?.endTime)
        body.details.endTime = moment(values?.endTime, "HH:mm").format("LT");
      if (values?.taskDate)
        body.details.taskDate = moment(values?.taskDate)?.format();

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) =>
      !!id
        ? updateDataList(id, body, values?.company?.domain)
        : addDataList(body, values?.company?.domain),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate("../tasklist", { state: state ? state : values?.company });
    },
  });

  const loadLocations = async (search, loadedOptions, { page }) => {
    let resp =
      values?.company?.domain &&
      (await getCompanyLocation(
        values?.company?.domain,
        page,
        constant.PER_PAGE_TEN,
        search
      ));

    let array = (await resp?.data?.data?.locations?.locations) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
      })),
      hasMore:
        loadedOptions.length == resp?.data?.data?.locations?.total
          ? false
          : true,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadUser = async (search, loadedOptions, { page }) => {
    let resp =
      values?.company?.domain &&
      (await getUser(
        page,
        constant.PER_PAGE_TEN,
        search,
        constant.ROLES.USER,
        values?.company?.value
      ));

    let array = (await resp?.data?.data?.users) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.userInfo?.name,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadCompany = async (search, loadedOptions, { page }) => {
    let resp = await getAllCompany(page, constant.PER_PAGE_TEN, search);
    let array = (await resp?.data?.data?.companies) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
        domain: item?.uuid,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  useQuery({
    queryKey: ["task-details", id],
    queryFn: async () => {
      const resp =
        !!state?.domain &&
        !!id &&
        (await getDataListbyId(id, state?.domain, true, true));
      let data = resp?.data?.data;
      if (data) {
        setValues({
          ...values,
          company: state,
          taskListName: data?.name,
          taskName: data?.details?.taskName,
          locations: data?.locations?.map((i) => ({
            value: i?._id,
            label: i?.name,
            domain: i?.slug,
          })),
          assignAll: data?.assignAll,
          startCheck: data?.details?.startTime ? "true" : "false",
          startTime: data?.details?.startTime
            ? moment(data?.details?.startTime, "LT").format("HH:mm")
            : "",
          endCheck: data?.details?.endTime ? "true" : "false",
          endTime: data?.details?.endTime
            ? moment(data?.details?.endTime, "LT").format("HH:mm")
            : "",
          specificCheck: !!data?.customerDetails ? "true" : "false",
          specificEmployee: {
            label: data?.customerDetails?.userInfo?.name,
            value: data?.customerDetails?._id,
          },
          dailyTask: data?.details?.dailyTask ? "true" : "false",
          taskDate: data?.details?.taskDate
            ? moment(data?.details?.taskDate)?.format("YYYY-MM-DD")
            : "",
          pictureRequired: data?.details?.pictureRequired ? "true" : "false",
          image: data?.details?.image,
        });
      }

      return true;
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12}>
            <NavigateBack path="../tasklist" state={state}>
              {!!id ? t("task.editTask") : t("task.addNewTask")}
            </NavigateBack>
          </Col>
        </Row>
        <div className="basic_Info">
          <Row>
            <Col md={12}>
              <div className="w-100">
                <label htmlFor="name" className="fontSize16 mb-2">
                  {t("user.company")} <span className="text-danger">*</span>
                </label>
                <DropDown
                  placeholder={t("user.selectCompany")}
                  id="company"
                  isDisabled={!!id}
                  loadOptions={loadCompany}
                  isClearable={false}
                  onChange={(e) => {
                    setFieldValue("company", e);
                    setFieldValue("locations", []);
                  }}
                  value={values?.company}
                  onBlur={() => setFieldTouched("company", true)}
                />
                <small className="text-danger ms-1">
                  {touched?.company && errors?.company?.value}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="taskListName" className="companyLabel">
                  {t("task.taskListName")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="taskListName"
                  name="taskListName"
                  value={values?.taskListName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("task.enterTaskListName")}
                />
                <small className="text-danger ms-1">
                  {touched?.taskListName && errors?.taskListName}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="taskName" className="companyLabel">
                  {t("task.taskName")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="taskName"
                  className="commonInput"
                  placeholder={t("task.enterTaskName")}
                  value={values?.taskName}
                  name="taskName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <small className="text-danger ms-1">
                  {touched?.taskName && errors?.taskName}
                </small>
              </div>
            </Col>

            <Col md={12}>
              <div className="mt-3">
                <label htmlFor="taskLocation" className="companyLabel">
                  {t("task.taskLocation")}
                  <span className="text-danger">*</span>
                </label>
                <DropDown
                  placeholder={t("task.enterTaskLocation")}
                  id="taskLocation"
                  loadOptions={loadLocations}
                  isClearable={false}
                  defaultOptions={true}
                  depends={values?.company?.domain}
                  isMulti={true}
                  value={values?.locations}
                  onChange={(e) => setFieldValue("locations", e)}
                  onBlur={() => setFieldTouched("locations", true)}
                />
                <small className="text-danger ms-1">
                  {touched?.locations && errors?.locations}
                </small>
              </div>
            </Col>
            <Col md={12}>
              <div className="customer_Access">
                <div className="access_Child">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="assignAll"
                      name="assignAll"
                      value={values?.assignAll}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values?.assignAll == true}
                    />
                    <label htmlFor="assignAll">
                      <span className="fontSize14 ms-2">
                        {t("task.applyToallCurrentAndFutureLocations")}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={6}>
              <h3 className="fontSize16 pt-4 pb-2">{t("task.setStartTime")}</h3>
              <div className="select_Role d-flex align-items-center">
                <div>
                  <input
                    type="radio"
                    id="startTime-yes"
                    name="startCheck"
                    value={"true"}
                    checked={values?.startCheck == "true"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="startTime-yes"
                    className="position_Label free"
                  >
                    {t("task.yes")}
                  </label>
                </div>
                <div className="positionLabel_Parent">
                  <input
                    type="radio"
                    id="startTime-no"
                    name="startCheck"
                    value={"false"}
                    checked={values?.startCheck == "false"}
                    onChange={handleChange}
                    onClick={() => setFieldValue("startTime", "")}
                  />
                  <label htmlFor="startTime-no" className="position_Label">
                    {t("task.no")}
                  </label>
                </div>
              </div>
              <div className="mt-2">
                <input
                  type="time"
                  name="startTime"
                  disabled={values?.startCheck == "false"}
                  value={values?.startTime}
                  ref={startDateRef}
                  onClick={() => startDateRef.current.showPicker()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                />
              </div>
            </Col>
            <Col xl={6}>
              <h3 className="fontSize16 pt-4 pb-2">{t("task.setEndTime")}</h3>
              <div className="select_Role d-flex align-items-center">
                <div>
                  <input
                    type="radio"
                    id="endTime-yes"
                    name="endCheck"
                    value={"true"}
                    checked={values?.endCheck == "true"}
                    onChange={handleChange}
                  />
                  <label htmlFor="endTime-yes" className="position_Label free">
                    {t("task.yes")}
                  </label>
                </div>
                <div className="positionLabel_Parent">
                  <input
                    type="radio"
                    id="endTime-no"
                    name="endCheck"
                    value={"false"}
                    checked={values?.endCheck == "false"}
                    onChange={handleChange}
                    onClick={() => setFieldValue("endTime", "")}
                  />
                  <label htmlFor="endTime-no" className="position_Label">
                    {t("task.no")}
                  </label>
                </div>
              </div>
              <div className="mt-2">
                <input
                  type="time"
                  className="commonInput"
                  name="endTime"
                  disabled={values?.endCheck == "false"}
                  value={values?.endTime}
                  ref={endDateRef}
                  onClick={() => endDateRef?.current?.showPicker()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <small className="text-danger ms-1">
                  {touched?.endTime && errors?.endTime}
                </small>
              </div>
            </Col>
            <Col xl={6}>
              <h3 className="fontSize16 pt-4 pb-2">
                {t("task.specificeEmployee")}
              </h3>
              <div className="select_Role d-flex align-items-center">
                <div>
                  <input
                    type="radio"
                    id="specificeEmployee-yes"
                    name="specificCheck"
                    value={"true"}
                    checked={values?.specificCheck == "true"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="specificeEmployee-yes"
                    className="position_Label free"
                  >
                    {t("task.yes")}
                  </label>
                </div>
                <div className="positionLabel_Parent">
                  <input
                    type="radio"
                    id="specificEmployee-no"
                    name="specificCheck"
                    value={"false"}
                    checked={values?.specificCheck == "false"}
                    onChange={handleChange}
                    onClick={() => setFieldValue("specificEmployee", "")}
                  />
                  <label
                    htmlFor="specificEmployee-no"
                    className="position_Label"
                  >
                    {t("task.no")}
                  </label>
                </div>
              </div>
              <div className="mt-2">
                <DropDown
                  placeholder={t("task.enterEmployeeName")}
                  loadOptions={loadUser}
                  depends={values?.company?.domain}
                  id="specificEmployee"
                  isClearable={false}
                  isDisabled={values?.specificCheck == "false"}
                  value={values?.specificEmployee}
                  onChange={(e) => setFieldValue("specificEmployee", e)}
                  onBlur={() => setFieldTouched("specificEmployee", true)}
                />
              </div>
            </Col>
            <Col xl={6}>
              <h3 className="fontSize16 pt-4 pb-2">
                {t("task.dailyRecuuringTask")}
              </h3>
              <div className="select_Role d-flex align-items-center">
                <div>
                  <input
                    type="radio"
                    id="dailyTask-yes"
                    name="dailyTask"
                    value={"true"}
                    checked={values?.dailyTask == "true"}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("taskDate", "");
                    }}
                  />
                  <label
                    htmlFor="dailyTask-yes"
                    className="position_Label free"
                  >
                    {t("task.yes")}
                  </label>
                </div>
                <div className="positionLabel_Parent">
                  <input
                    type="radio"
                    id="dailyTask-no"
                    name="dailyTask"
                    value={"false"}
                    onChange={handleChange}
                    checked={values?.dailyTask == "false"}
                  />
                  <label htmlFor="dailyTask-no" className="position_Label">
                    {t("task.no")}
                  </label>
                </div>
              </div>
              {values?.dailyTask == "false" && (
                <div className="mt-2">
                  <input
                    type="date"
                    className="commonInput"
                    name="taskDate"
                    value={values?.taskDate}
                    min={moment().format("YYYY-MM-DD")}
                    ref={dateRef}
                    onClick={() => dateRef.current.showPicker()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <small className="text-danger ms-1">
                    {touched?.taskDate && errors?.taskDate}
                  </small>
                </div>
              )}
            </Col>
            <Col xl={12}>
              <h3 className="fontSize16 pt-4 pb-2">
                {t("task.pictureRequired")}
              </h3>
              <div className="select_Role d-flex align-items-center">
                <div>
                  <input
                    type="radio"
                    id="image-yes"
                    name="pictureRequired"
                    value={"true"}
                    checked={values?.pictureRequired == "true"}
                    onChange={handleChange}
                  />
                  <label htmlFor="image-yes" className="position_Label free">
                    {t("task.yes")}
                  </label>
                </div>
                <div className="positionLabel_Parent">
                  <input
                    type="radio"
                    id="image-no"
                    name="pictureRequired"
                    value={"false"}
                    checked={values?.pictureRequired == "false"}
                    onChange={handleChange}
                    onClick={() => setFieldValue("picture", "")}
                  />
                  <label htmlFor="image-no" className="position_Label">
                    {t("task.no")}
                  </label>
                </div>
              </div>
            </Col>

            <Col xl={12}>
              <h3 className="fontSize16 pt-4 pb-2">{t("task.addTaskImage")}</h3>
              <div className="file_Upload mt-3">
                <div {...getRootProps()} className="dropzone">
                  <input {...getInputProps()} />
                  {!(values?.picture || values?.image) && !isDragActive && (
                    <p className="text-center">{t("globals.dragAndDrop")}</p>
                  )}
                  {isDragActive ? (
                    <p>{t("globals.dropFilesHere")}</p>
                  ) : (
                    (values?.picture || values?.image) && (
                      <div className="image-preview">
                        <img
                          src={
                            values?.picture
                              ? URL.createObjectURL(values?.picture)
                              : values?.image
                          }
                          alt="Preview"
                          className="image-preview"
                        />
                      </div>
                    )
                  )}
                  {!(values?.picture || values?.image) && !isDragActive && (
                    <button className="userBtn btn-theme fontSize18 uploadFile_Btn">
                      <UploadIcon />{" "}
                      <span className="ms-2">{t("task.upload")}</span>
                    </button>
                  )}
                </div>
              </div>
            </Col>
            <div className=" mb-2">
              <button
                className="userBtn btn-theme fontSize18 m-0 mt-3"
                type="button"
                onClick={handleSubmit}
              >
                {!!id ? t("globals.saveChanges") : t("globals.add")}
              </button>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddNewTask;
