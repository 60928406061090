import moment from "moment/moment";
import React, { useRef, useState } from "react";
import { FaCalendar } from "react-icons/fa6";
import { CrossIcon } from "../../../SvgIcons/allIcons";
import { dateFormat } from "../../../utils/labelConstants";
import DateFormatModel from "./DateFormatModel";

export default function DateInput({
  element,
  preview,
  handleRemoveObject,
  setSelectedElement,
  selectedElement,
  setItem,
}) {
  const textareaRef = useRef(null);
  const [show, setShow] = useState(false);

  const handleInput = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleValue = () => {
    if (!element?.value) return "";

    const date = new Date(element.value);
    const format =
      element.format === dateFormat.monthFirst ? "MM/DD/YYYY" : "DD/MM/YYYY";
    const formattedDate = moment(date).format(format);
    const formattedTime = moment(date).format("hh:mm A");
    const formattedDay = moment(date).format("dddd");

    const {
      date: includeDate,
      time: includeTime,
      day: includeDay,
    } = element?.dateIncludes || {};

    if (includeDate && includeTime && includeDay) {
      return `${formattedDay} ${formattedDate} at ${formattedTime}`;
    } else if (includeDate && includeTime) {
      return `${formattedDate} at ${formattedTime}`;
    } else if (includeDate && includeDay) {
      return `${formattedDay} ${formattedDate}`;
    } else if (includeTime && includeDay) {
      return `${formattedDay} ${formattedTime}`;
    } else if (includeDate) {
      return formattedDate;
    } else if (includeTime) {
      return formattedTime;
    } else {
      return formattedDate;
    }
  };

  return (
    <div
      className="position-relative"
      onClick={() => setSelectedElement(element)}
    >
      {preview ? (
        !element?.value ? (
          <></>
        ) : (
          <div className="label-input-preview d-flex gap-1">
            {element.showLabel && (
              <label className="labelMain" {...element.input}>
                {`${element.labelText}:`}
              </label>
            )}
            <p className="commonInput" {...element.input}>
              {handleValue() || ""}
            </p>
          </div>
        )
      ) : (
        <div className="label-input d-flex gap-1">
          {element.isCustom ? (
            <>
              <textarea
                ref={textareaRef}
                onInput={handleInput}
                rows={1}
                style={{
                  overflow: "hidden",
                  resize: "none",
                  ...element.input.style,
                }}
                value={element.labelText || ""}
                onChange={(e) =>
                  setItem((prevItems) =>
                    prevItems.map((item) =>
                      item.id === element.id
                        ? { ...item, labelText: e.target.value }
                        : item
                    )
                  )
                }
                className="commonInput"
                name="taskDate"
                placeholder={element.placeholder || ""}
              />{" "}
              <p className="labelMain" {...element.input}>
                :
              </p>
            </>
          ) : (
            element.showLabel && (
              <label className="labelMain" {...element.input}>
                {`${element.labelText}:`}
              </label>
            )
          )}
          <p className="commonInput" {...element.input}>
            {element.value ? handleValue() : element.placeholder}
          </p>
          {selectedElement?.id == element.id && (
            <span
              className="cross-icon-label ms-1"
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveObject(element.id);
                setSelectedElement("");
              }}
            >
              <CrossIcon size={13} />
            </span>
          )}
          <span
            className="calendar-icon-label ms-1"
            role="button"
            onClick={() => {
              setShow(true);
            }}
          >
            <FaCalendar size={10} />
          </span>
        </div>
      )}
      {show && (
        <DateFormatModel
          show={show}
          handleClose={() => setShow(false)}
          setItem={setItem}
          element={element}
        />
      )}
    </div>
  );
}
