import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Loader from "../../components/Loader/Loader";
import useSlider from "../../hooks/useSlider";
import {
  addBrand,
  deleteBrand,
  getAllBrand,
  updateBrand,
} from "../../services/services";
import { CrossIcon, Search } from "../../SvgIcons/allIcons";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import AdminFooter from "../AdminFooter";
import Sidebar from "../sidebar/Sidebar";
import DeleteModal from "../../components/DeleteModal";
import { constant } from "../../utils/constants";
import { noSpecialChars } from "../../helper/helper";

const Brand = () => {
  const [show, setShow] = useState();
  const handleClose = () => {
    setShow(false);
    resetForm();
  };
  const [showDelete, setShowDelete] = useState(false);
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const isSlider = useSlider();
  const { t } = useTranslation();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: {
      brandId: "",
      brandName: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      brandName: yup
        .string()
        .required()
        .label(t("brand.brandName"))
        .trim()
        .max(50).min(1),
      description: yup.string().label(t("brand.description")).max(500),
    }),
    onSubmit: (values) => {
      let body = {
        name: values?.brandName,
      };
      if (values?.description) {
        body.description = values?.description;
      }

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) =>
      values?.brandId ? updateBrand(values?.brandId, body) : addBrand(body),
    onSuccess: (resp) => {
      resetForm();
      setShow(false);
      refetch();
      toastAlert("success", resp?.data?.message);
    },
  });

  const { data, refetch } = useQuery({
    queryKey: ["brand-list", page, limit],
    queryFn: async () => {
      let resp = await getAllBrand(page, search, limit);
      return resp?.data?.data;
    },
  });

  const handleEdit = (data) => {
    setShow(true);
    setValues({
      ...values,
      brandId: data?._id,
      brandName: data?.name,
      description: data?.description,
    });
  };

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteBrand(id),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6} >
            <h2 className="mainhead font-24">{t("brand.brandmanagement")}</h2>
          </Col>
          <Col className="text-end" xl={6}>
            <Button
              className="userBtn btn-theme"
              type="button"
              onClick={() => setShow(true)}
            >
              {t("globals.addNew")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <div className="sidebarBox mt-3">
              <div className="sidebar-box search-form-wrap mb-0">
                <div className="search-form">
                  <div className="form-group">
                    <span className="iconsearch">
                      <Search />
                    </span>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={(e) => {
                        noSpecialChars(e);
                        if (e.key == "Enter" && search.trim() !== "") refetch();
                      }}
                      onKeyUp={(e) =>
                        e.target.value == "" &&
                        e.key == "Backspace" &&
                        refetch()
                      }
                      type="text"
                      className="form-control"
                      placeholder={t("globals.search")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="table-responsive companyManagTable tableOut mt-4">
          <Table className="tableDashboard" responsive>
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th>{t("brand.brandName")}</th>
                <th>{t("brand.description")}</th>
                <th>{t("globals.createdAt")}</th>
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.brands && data?.brands?.length > 0 ? (
                data?.brands?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{(page - 1) * limit + (index + 1)}</td>
                      <td>{item?.name}</td>
                      <td>{!!item?.description ? item?.description : "N/A"}</td>
                      <td>{moment(item?.createdAt).format("lll")}</td>
                      <td>
                        <img
                          src="/images/Edit.svg"
                          className="img-fluid actionIcon"
                          onClick={() => handleEdit(item)}
                        />
                        <img
                          src="/images/Delete.svg "
                          className="img-fluid actionIcon"
                          onClick={() => setShowDelete(item?._id)}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("brand.notFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.brands?.length}
        />
      </div>
      <AdminFooter />

      {/*********************************Add Edit Modal***************************/}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header className="newPlan_ModalHeader" closeButton={false}>
          <Modal.Title>
            {values?.brandId ? t("brand.updateBrand") : t("brand.addBrand")}{" "}
          </Modal.Title>
          <Button variant="link" onClick={handleClose} className="close_Btn">
            <CrossIcon />
          </Button>
        </Modal.Header>

        <Modal.Body className="newPlan_ModalBody">
          <Form>
            <div className="mb-3">
              <label htmlFor="brandName" className="companyLabel">
                {t("brand.brandName")} <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="brandName"
                name="brandName"
                value={values?.brandName}
                onChange={handleChange}
                onBlur={handleBlur}
                className="commonInput"
                placeholder={t("brand.enterBrandName")}
              />
              <small className="text-danger ms-1">
                {touched.brandName && errors.brandName}
              </small>
            </div>
            <div>
              <label htmlFor="description" className="companyLabel">
                {t("brand.description")}
              </label>
              <textarea
                rows={3}
                id="description"
                name="description"
                value={values?.description}
                onChange={handleChange}
                onBlur={handleBlur}
                className="commonInput"
                placeholder={t("brand.enterDescription")}
              />
              <small className="text-danger ms-1">
                {touched.description && errors.description}
              </small>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer className="newPlan_ModalFooter">
          <Button
            variant="secondary"
            className="userBtn btn-theme planModalFooter_Btn"
            type="button"
            onClick={handleSubmit}
          >
            {t("brand.save")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/******************************Delete Modal*************************/}
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />

      {mutation?.isPending && <Loader />}
    </div>
  );
};

export default Brand;
