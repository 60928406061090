
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CompanyDropDown from "../../components/CompanyDropDown";
import DeleteModal from "../../components/DeleteModal";
import { noSpecialChars } from "../../helper/helper";
import useSlider from "../../hooks/useSlider";
import {
    deletePrinters,
    getAllPrinters,
} from "../../services/services";
import { Search } from "../../SvgIcons/allIcons";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";

function PrinterManagement() {
    const isSlider = useSlider();
    const { t } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation()
    const [company, setCompany] = useState(location?.state ? location?.state : "");
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(constant.PAGE_ONE);
    const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
    const [showDelete, setShowDelete] = useState(false);
    const { data, refetch } = useQuery({
        queryKey: ["printer-list", page, limit, company?.domain],
        queryFn: async () => {
            const resp =
                company?.domain && (await getAllPrinters(company?.domain, page, limit, search));
            return resp?.data?.data ?? [];
        },
    });

    const deleteMutation = useMutation({
        mutationFn: (id) => deletePrinters(company?.domain, id),
        onSuccess: (resp) => {
            refetch();
            setShowDelete(false);
            toastAlert("success", resp?.data?.message);
        },
    });

    return (
        <div className="mainbox">
            <Sidebar />
            <div className={isSlider ? "body-content close" : "body-content open"}>
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className="mainhead font-24">{t("printer.printerManagement")}</h2>
                    <div className="d-flex align-items-center gap-2">
                        <CompanyDropDown company={company} setCompany={setCompany} />
                        <button
                            className="userBtn btn-theme me-2"
                            onClick={() => navigate("../printers/modify")}
                        >
                            {t("printer.assignPrinter")}
                        </button>
                    </div>
                </div>
                <Row>
                    <Col xl={12}>
                        <div className="sidebarBox mt-3">
                            <div className="sidebar-box search-form-wrap mb-0">
                                <div className="search-form">
                                    <div className="form-group">
                                        <span className="iconsearch">
                                            <Search />
                                        </span>
                                        <input
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            onKeyDown={(e) => {
                                                noSpecialChars(e);
                                                if (e.key == "Enter" && search.trim() !== "") refetch();
                                            }}
                                            onKeyUp={(e) =>
                                                e.target.value == "" &&
                                                e.key == "Backspace" &&
                                                refetch()
                                            }
                                            type="text"
                                            className="form-control"
                                            placeholder={t("globals.search")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="table-responsive tableOut mt-4">
                    <table className="table tableDashboard">
                        <thead className="thead-dark">
                            <tr>
                                <th>{t("printer.deviceId")}</th>
                                <th>{t("printer.name")}</th>
                                <th>{t("printer.printerModel")}</th>
                                <th>{t("printer.serialNumber")}</th>
                                <th>{t("printer.locationName")}</th>
                                <th>{t("printer.address")}</th>
                                <th>{t("globals.action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.printers && data?.printers?.length > 0 ? (
                                data?.printers?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item?.deviceId || "--"}</td>
                                            <td>{item?.name || "--"}</td>
                                            <td>{item?.model || "--"}</td>
                                            <td>{item?.serialNumber || "--"}</td>
                                            <td>{item?.locationDetails?.name || "--"}</td>
                                            <td>{item?.locationDetails?.billingAddress?.fullAddress || "--"}</td>
                                            <td>
                                                <Link
                                                    to={`../printers/modify/?id=${item?._id}`}
                                                    role="button"
                                                    state={company}
                                                >
                                                    <img src="/images/Edit.svg" alt="" />
                                                </Link>
                                                <img
                                                    src="./../images/Delete.svg"
                                                    alt="delete"
                                                    onClick={() => setShowDelete(item?._id)}
                                                    role="button"
                                                />
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={10} className="text-center">
                                        <h4>{t("printer.notFound")}</h4>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                    totalPages={data?.total_pages}
                    total={data?.total}
                    length={data?.printers?.length}
                />
                <DeleteModal
                    show={showDelete}
                    handleClose={() => setShowDelete(false)}
                    handleDelete={deleteMutation.mutate}
                />
            </div>
        </div>
    )
}

export default PrinterManagement