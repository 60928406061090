import React from "react";
import { CrossIcon } from "../../../SvgIcons/allIcons";

const QRBar = ({ handleRemoveObject, element, preview }) => {
  if (preview) {
    return (
      <div className=" h-100 position-relative afterPreview">
        <div className="dropzone">
          <div className="image-preview h-100 w-100 p-1">
            <img
              src={element.src}
              alt="Preview"
              className="label-after-image"
              height={element.styles.height - 15}
              width={element.styles.width - 15}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="file_Upload h-100 position-relative">
      <span
        className="cross-icon-label"
        role="button"
        onClick={() => handleRemoveObject(element.id)}
      >
        <CrossIcon size={13} />
      </span>

      <div className="dropzone">
        <div className="image-preview h-100 w-100 p-1">
          <img
            src={element.src}
            alt="Preview"
            className="label-after-image"
            height={element.styles.height - 15}
            width={element.styles.width - 15}
          />
        </div>
      </div>
    </div>
  );
};

export default QRBar;
