import React from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineFileUpload } from "react-icons/md";
import { CrossIcon } from "../../../SvgIcons/allIcons";

const ImageInput = ({
  handleRemoveObject,
  handleImageChange,
  element,
  preview,
}) => {
  const { t } = useTranslation();

  if (preview) {
    return (
      <div className=" h-100 position-relative afterPreview">
        <div className="dropzone">
          <div className="image-preview h-100 w-100 p-1">
            <img
              src={element.src}
              alt="Preview"
              className="label-after-image"
              height={element.styles.height - 15}
              width={element.styles.width - 15}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="file_Upload h-100 position-relative">
      <span
        className="cross-icon-label"
        role="button"
        onClick={() => handleRemoveObject(element.id)}
      >
        <CrossIcon size={13} />
      </span>

      <div className="dropzone">
        <input
          className="d-none"
          type="file"
          id={`logo-${element.id}`}
          accept="image/*"
          onChange={(e) => handleImageChange(e, element)}
        />
        {element.src ? (
          <div className="image-preview h-100 w-100 p-1">
            <img
              src={element.src}
              alt="Preview"
              className="label-after-image"
              height={element.styles.height - 15}
              width={element.styles.width - 15}
            />
          </div>
        ) : (
          <div className="label-image">
            <p className="text-center mb-1">{t("globals.uploadLogoHere")}</p>

            <label
              className="upload-btn btn-theme fontSize18 "
              htmlFor={`logo-${element.id}`}
            >
              <MdOutlineFileUpload size={10} /> <span>{t("task.upload")}</span>
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageInput;
